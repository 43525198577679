@media (max-width: 767px){
    .delicon{
     display: none;
    }
    #capture table, #capture table td.container{
        max-width: 100%!important;
        width:100%!important;
    }
    .sample-text {
        font-size:8px;
    }
    }
    .unread-message a{
        color:#F58025!important;
        font-weight: 600;
    }
    .inline-block{
        display: inline-block;
        /* width:70%; */
        line-height: 37px;
    }
.pnc-reward-block-wrap .pnc-delete{
    margin-left:20px;
    line-height: 39px;
}    